import axios from 'axios';
import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

export const createGameRegistration = async (payload) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/game-registration`;
  const { data } = await axiosInstance.post(url, payload, {
    headers: {
      authorization: localStorage.getItem('accessToken')
        ? `Bearer ${localStorage.getItem('accessToken')}`
        : null,
    },
  });
  return data;
};

export const getGameRegistration = async (userId = null) => {
  const url = userId
    ? `${process.env.REACT_APP_BACKEND_URL}api/game-registrations/${userId}`
    : `${process.env.REACT_APP_BACKEND_URL}api/game-registrations`;
  const { data } = await axiosInstance.get(url, {
    headers: {
      authorization: localStorage.getItem('accessToken')
        ? `Bearer ${localStorage.getItem('accessToken')}`
        : null,
    },
  });
  return data;
};

// Get all games
export const getAllGames = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/games`;
  try {
    const { data } = await axiosInstance.get(url, {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    });
    return data;
  } catch (error) {
    console.error('Error fetching games:', error);
    throw error;
  }
};
