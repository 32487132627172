import axios from 'axios';
import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

// get all user data
export const getRegisterUserData = async (userId = null) => {
  // const url = `${API_END_POINTS.AUTH.NEW_REGISTER}`;
  let url = `${process.env.REACT_APP_BACKEND_URL}api/organizations`;
  if (userId) {
    url += `?userId=${userId}`;
  }
  const { data } = await axiosInstance.get(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return data;
};

export const createRegistration = async (payload) => {
  // const url = `${API_END_POINTS.AUTH.NEW_REGISTER}`;
  const url = `${process.env.REACT_APP_BACKEND_URL}api/organization`;
  const { data } = await axiosInstance.post(url, payload, {
    headers: {
      authorization: localStorage.getItem('accessToken')
        ? `Bearer ${localStorage.getItem('accessToken')}`
        : null,
    },
  });
  return data;
};

export const deleteRegistration = async (id) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/organization/${id}`;
  const { data } = await axiosInstance.delete(url, {
    headers: {
      authorization: localStorage.getItem('accessToken')
        ? `Bearer ${localStorage.getItem('accessToken')}`
        : null,
    },
  });
};

export const updateRegistration = async (id, payload) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/organization/${id}`;
  const { data } = await axiosInstance.put(url, payload, {
    headers: {
      authorization: localStorage.getItem('accessToken')
        ? `Bearer ${localStorage.getItem('accessToken')}`
        : null,
    },
  });
  return data;
};
